import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSearchParams } from "react-router-dom";

interface Props {
    refreshToken: string;
}
interface DashboardPayload {
    id: number;
    dashboard_type: string;
    token: string;
}



interface ResponseDashboardApiData {
    iframeURL: string;
    metabase_token: string;
    metabase_url: string;
    get_response_forwarder_to_metabase_api: string;
}

interface ResponseDashboardApi {
    data: ResponseDashboardApiData;
}

const metabaseHost = process.env.REACT_APP_BRIDGE_METABASE_HOST;
const apiHost = process.env.REACT_APP_API_ENTRYPOINT;

let getIframe: any;
getIframe = async (dashboardPayload: DashboardPayload, token: string) =>
    await axios.post(`${metabaseHost}/api/views/viewsdynamic`, {
        ...dashboardPayload,
        token
    }).then((response: ResponseDashboardApi) => {
        if (response && response.data) {
            if (response.data.iframeURL) {
                return response.data.iframeURL
            }
        }

        return null;
    })
;

let getToken : any;
getToken = async (refreshToken: string) => {
    return await axios.post(`${apiHost}/token/refresh`, {
        refresh_token: refreshToken
    }).then(response => {
        if (response && response.status === 200) {
            return response.data
        }
    })
}

const SegmentDashboard = (props: Props) => {
    const [iframeUrl, setIframeUrl] = useState<string | null>(null);
    let [searchParams] = useSearchParams();
    const identification = searchParams.get("dashboard_id")
    const dashboardType = searchParams.get("dashboard_type")

    useEffect(() => {
        getToken(props.refreshToken).then((data: {token: string}) => {
            if(identification && dashboardType) {
                const dashboardPayload: DashboardPayload = {
                    id: Number(identification),
                    dashboard_type: dashboardType,
                    token: ''
                }
                getIframe(dashboardPayload, data.token).then((iframeUrl: string) => {
                    if (iframeUrl) setIframeUrl(iframeUrl)
                });
            }
        });
    }, [props.refreshToken, dashboardType, identification]);

    return (
        <div style={{ height: '100%', width: '100%', position: 'absolute' }} id={'segmentDashboard'}>
            {iframeUrl && (
                <iframe
                    title={"Capremib dashboard / Metabase question 171"}
                    src={iframeUrl}
                    id={'segmentMetabaseIframe'}
                    style={{ width: '100%', height: '100%', border: '0' }}
                    width={'100%'}
                    height={'100%'}
                />
            )}
        </div>
    );
};

export default SegmentDashboard;
