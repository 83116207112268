import React from "react"
import { useParams } from 'react-router-dom';
import {REFRESH_TOKEN_ROUTE_PARAMS} from "./path";
import CapremibSegmentDashboard from "../components/dashboard/CapremibSegmentDashboard";

const CapremibSegmentPage = () => {
    // @ts-ignore
    let {[REFRESH_TOKEN_ROUTE_PARAMS]: refreshToken}  = useParams();
    return (
      <>
          {refreshToken && <CapremibSegmentDashboard refreshToken={refreshToken}/>}
      </>
  )
}

export default CapremibSegmentPage;
