import React from 'react';
// @ts-ignore
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import {  segmentsPathExternal } from './path';
import CapremibSegmentPage from './CapremibSegmentPage';
const RootPage = () => {
    return (
        <Router>
            <Routes>
                {/*@ts-ignore*/}
                <Route exact path={segmentsPathExternal} element={<CapremibSegmentPage />} />
                {/*@ts-ignore*/}
                <Route exact path='/unauthorized' />
            </Routes>
        </Router>
    );
};

export default RootPage;
